import { render, staticRenderFns } from "./AuctionDownloadButton.vue?vue&type=template&id=0bba4054&scoped=true&"
import script from "./AuctionDownloadButton.vue?vue&type=script&lang=js&"
export * from "./AuctionDownloadButton.vue?vue&type=script&lang=js&"
import style0 from "./AuctionDownloadButton.vue?vue&type=style&index=0&id=0bba4054&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bba4054",
  null
  
)

export default component.exports